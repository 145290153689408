import { Container, Row, Col } from 'react-bootstrap';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';


const ScrollToTopButton = () => {
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <button className="scroll-to-top-button" onClick={handleScrollToTop}>
            <i className="bi bi-arrow-up"></i>
        </button>
    );
};

const Quartieri = () => {
    const [quartieri, setQuartieri] = useState([]);
    const [quartiere, setQuartiere] = useState(null); 
    const descrizioneQuartieriRef = useRef(null);

    useEffect(() => {
        const fetchQuartieri = async () => {
            try {
                const response = await axios.get('dati/quartieri.json');
                setQuartieri(response.data);
                setQuartiere(response.data[0]) 
            } catch (error) {
                console.error('Errore durante il recupero dei dati dei Quartieri:', error);
            }
        };

        fetchQuartieri();
    }, []);




    const quartieriscrollToQuartiere = (quartiere) => {
        setQuartiere(quartiere)
        const offsetTop = descrizioneQuartieriRef.current.offsetTop - 150;
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    };


    return (
        <div style={{ textAlign: 'center' }}>
            <div className='barra-titolo'>
                <h1><i className="bi bi-map"></i>  Programma quartieri</h1>
            </div>
            {quartieri.length > 0 && (
                <Container>
                    <Row>
                        {quartieri.map((qrt, index) => (
                            <Col key={index} xs={4} md={4} xl={3} >
                                <div className='quartiere' onClick={() => quartieriscrollToQuartiere(qrt)} style={{color: (qrt.nome===quartiere.nome)? '#fbbc00' : 'black',background: (qrt.nome===quartiere.nome)? 'whitesmoke' : 'white'}}> 
                                    <img        src={`${qrt.urlImmagine}`} alt={`${qrt.nome}`} />
                                    <div>
                                        {qrt.nome}
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            )}
            {quartiere && (

                <div ref={descrizioneQuartieriRef} className='programma-quartiere'>
                    <div className='barra-titolo' style={{marginBottom : '0', marginTop:'10px'}}>
                        <h2 > <i className="bi bi-file-earmark-text"></i> Programma <span style={{fontWeight:'bold'}}>{quartiere.nome}</span></h2>
                    </div>
                    <div style={{ width: '100%', height: '70vh' }}><iframe
                        src={`${quartiere.urlFile}`}
                        title="PDF Viewer"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        allowFullScreen
                    />
                    </div>
                </div>
            )}

            <ScrollToTopButton />
        </div>
    );
}

export default Quartieri;
