

function ModalCaricamento() {

  return (
    <div className="modal-caricamento">
      <div>
        <img src="/img/logo.png" alt="logo" className="logo-nav" /> <span style={{ fontSize: "24px", paddingLeft: '5px', fontWeight: '500', textTransform: 'uppercase' }}>il momento</span>
      </div>
      <div className='animate__animated animate__flipInX animate__infinite'>
        <h1>Caricamento <i className="bi bi-hourglass-bottom "></i></h1>
      </div>

    </div>
  );
}

export default ModalCaricamento;