import React, { useState, useEffect } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { NavLink,Link } from "react-router-dom";

const Header = () => {
    const [expanded, setExpanded] = useState(false);

    const closeNavbar = () => setExpanded(false);

    useEffect(() => {
        /*fix element on top when user scroll over declared px */
        const handleScroll = () => {
            const scrollmenu = document.querySelector('.navbar');

            if (window.scrollY > 120) {
                scrollmenu.classList.add('fixed-menu');
            } else {
                scrollmenu.classList.remove('fixed-menu');
            }
        };

        window.addEventListener('scroll', handleScroll);

        /*chiamata automaticamente quando il componente si smonta, 
        e questo garantirà che l'ascoltatore dell'evento di scroll venga rimosso, evitando potenziali problemi di prestazioni o comportamenti inaspettati. */
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Navbar expanded={expanded} onToggle={setExpanded} collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
                <Navbar.Brand>
                    <NavLink to="/" className="nav-link" onClick={closeNavbar}>
                        <img src="/img/logo.png" alt="logo" className="logo-nav" /> <span className="nome-logo" >il momento</span>
                    </NavLink>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto"></Nav>
                    <Nav>
                        <Nav.Link as={NavLink} to="/" className="nav-link" onClick={closeNavbar}>
                            Home
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/candidati" className="nav-link" onClick={closeNavbar}>
                          Candidate e Candidati
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/candidato-sindaco" className="nav-link" onClick={closeNavbar}>
                          Candidato sindaco
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/manifesto" className="nav-link" onClick={closeNavbar}>
                             Manifesto
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/programma-completo" className="nav-link" onClick={closeNavbar}>
                             Programma completo
                        </Nav.Link>
                        
                         <Nav.Link as={NavLink} to="/programma-quartieri" className="nav-link" onClick={closeNavbar}>
                             Programma quartieri
                        </Nav.Link>
                      
                        
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
