import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap'; 

const photos = [
  { id: 1, src: "/img/foto/foto1.jpg", alt: 'Foto 1' },
  { id: 2, src: "/img/foto/foto2.jpg", alt: 'Foto 2' },
  { id: 3, src: "/img/foto/foto3.jpg", alt: 'Foto 3' },
  { id: 4, src: "/img/foto/foto4.jpg", alt: 'Foto 4' },
   { id: 5, src: "/img/foto/foto5.jpg", alt: 'Foto 5' },
  { id: 6, src: "/img/foto/foto6.jpg", alt: 'Foto 6' },  
 { id: 5, src: "/img/foto/foto7.jpg", alt: 'Foto 7' },
  { id: 6, src: "/img/foto/foto8.jpg", alt: 'Foto 8' }
];

function PhotoGallery() {
  const [showModal, setShowModal] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
    setShowModal(true);
  };



  return (
    <Container>
      <Row>
        {photos.map((photo, index) => (
          <Col key={index} xs={6} md={6} className="mb-3 " >
            <img
              className='foto-gallery invisible' // Aggiungi classe per nascondere l'immagine inizialmente
              src={photo.src}
              alt={photo.alt}
              style={{  cursor: 'pointer' }}
              width="100%"
              height='100%'
              onClick={() => handlePhotoClick(photo)}
            />
          </Col>
        ))}
      </Row>


      <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="modal-lg">
       {/*
           <Modal.Header closeButton>
           <Modal.Title>{selectedPhoto ? selectedPhoto.alt : ''}</Modal.Title>
         </Modal.Header>*/
       } 
        <Modal.Body>
          {selectedPhoto && (
            <img src={selectedPhoto.src} alt={selectedPhoto.alt} style={{ width: '100%' }} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default PhotoGallery;
