import { Carousel, Container, Row, Col } from 'react-bootstrap';
import React, { useState, useEffect } from 'react'; 
import PhotoGallery from '../components/photoGallery';
import ContactForm from '../components/contactform'; 
import { Link } from 'react-router-dom';
function Home() {

  
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.foto-gallery, .video-home,.box-testo');
      elements.forEach(element => {
        const bounding = element.getBoundingClientRect();
        if (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        ) {
          element.classList.remove('invisible'); // Rimuovi la classe che nasconde l'immagine
          element.classList.add('animate__animated', 'animate__zoomIn'); // Aggiungi classi animate per visualizzare l'immagine
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  //const CarouselWallpaper = ["img/presentazione0.jpg"];
  return (
    <div className="App">
      <div id="home" className="copertina">
        
      <img
                    className="d-block w-100"
                    src={"img/copertina.webp"}
                    alt="slide"
                    width="100%"
                  />
   {
           /* <Carousel fade>
            {
              CarouselWallpaper.map((item, index) =>
                <Carousel.Item key={index}>
               
                  <Carousel.Caption>
                    LISTA CIVICA PER LE PROSSIME ELEZIONI COMUNALI DI BASSANO DEL GRAPPA
                  </Carousel.Caption>
                  
  
                  <img
                    className="d-block w-100"
                    src={item}
                    alt="slide"
                  />
                </Carousel.Item>
              )
            }
          </Carousel>*/
   }
    
      </div>

      <div id="foto" style={{ textAlign: 'center' }}>
        <div className='barra-titolo' style={{ fontWeight: 'bold', fontSize: '16px' }}>
           LISTA CIVICA PER LE PROSSIME ELEZIONI COMUNALI DI BASSANO DEL GRAPPA
        </div>

        <Container>

          <div className='mb-4  mt-5 box-testo invisible'>
          Siamo una comunità aperta riunitasi per la formazione di un nuovo progetto politico che vuole dare un contributo pragmatico
           e innovativo alla vita della nostra città. Desideriamo costruire una rappresentanza politica che immagini un futuro di benessere per il nostro territorio, portando nel dibattito pubblico una visione di governance più sostenibile, innovativa, giusta e che possa esaltare sia il privato sociale che il privato economico.
          </div>

        </Container>
        <PhotoGallery  />
      </div>



{
 
      <div id="video" style={{ textAlign: 'center' }}>



        <Container>
          <div className='mb-4  mt-4 box-testo invisible'>
          è IL MOMENTO di mostrarvi un condensato della nostra voglia di metterci in gioco per questa meravigliosa città 💛❤️

          </div>
          <Row>

            <Col xs={12} xl={12} className="mb-3">
              <div className='video-home invisible'>
                <iframe
                  title='video-facebook2'
                  src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fe.il.momento.Bassano%2Fvideos%2F1377376569593803%2F&show_text=false&width=560&t=0"
                  style={{ border: "none", overflow: "hidden" }}
                  allowFullScreen={true}
                  scrolling="no"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                ></iframe>
              </div>
            </Col>
          </Row>


        </Container>
      </div>

}
   
      <ContactForm/>



    </div>
  );
}

export default Home;

