import React from 'react';

const Footer = () => {
  const address = "Via Orazio Marinali, 63 - 36061 Bassano del Grappa VI";
  const email = "info@eilmomento.org";

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <span>
            <i className="bi bi-geo-alt-fill"></i> <a style={{color:'white'}} href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`} target="_blank" rel="noopener noreferrer">
              {address}
            </a>
          </span>
          <span><i className="bi bi-envelope-fill"></i> Contatti: <a href={`mailto:${email}`} style={{color:'white'}}>{email}</a></span>
          <span  style={{fontSize:'18px'}}>
            <a   style={{color:'white',padding:'5px'}} href="https://www.facebook.com/e.il.momento.Bassano" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a>
            <a  style={{color:'white',padding:'5px'}} href="https://www.instagram.com/e.il.momento" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a>
          </span>
          <span>è IL MOMENTO - Tutti i diritti riservati &copy; {new Date().getFullYear()}</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
