import { Container, Row, Col } from 'react-bootstrap';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Slider from "react-slick";


const ScrollToTopButton = () => {
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <button className="scroll-to-top-button" onClick={handleScrollToTop}>
            <i className="bi bi-arrow-up"></i>
        </button>
    );
};

const Candidati = () => {
    const [candidati, setCandidati] = useState([]);
    const sliderRef = useRef(null);
    const descrizioneCandidatiRef = useRef(null);

    useEffect(() => {
        const fetchCandidati = async () => {
            try {
                const response = await axios.get('dati/candidati.json');
                setCandidati(response.data);
            } catch (error) {
                console.error('Errore durante il recupero dei dati dei candidati:', error);
            }
        };

        fetchCandidati();
    }, []);

    const slickSettings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,
        autoplay: false,
        autoplaySpeed: 6000,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768, // Breakpoint per dispositivi mobile
                settings: {
                    arrows: false // Nasconde le frecce sui dispositivi mobile
                },
            },
        ]
    };

    /*  const scrollToCandidate = (id) => {
          sliderRef.current.slickGoTo(id - 1);
          descrizioneCandidatiRef.current.scrollIntoView({ behavior: 'smooth' });
      };*/

    const scrollToCandidate = (id) => {
        sliderRef.current.slickGoTo(id - 1);
        const offsetTop = descrizioneCandidatiRef.current.offsetTop - 71;
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    };


    return (
        <div style={{ textAlign: 'center' }}>
            <div className='barra-titolo'>
                <h1 > <i className="bi bi-person-arms-up"></i> Candidate e Candidati</h1>
            </div>
            {candidati.length > 0 && (
                <Container>
                    <Row>
                        {candidati.map((candidato, index) => (
                            <Col key={index} xs={4} md={3} xl={2} >
                                <div className='candidato' onClick={() => scrollToCandidate(candidato.id)}>
                                    <img src={`img/candidati/${candidato.urlImmagine}`} alt={`${candidato.nome} ${candidato.cognome}`} />
                                    <div className='nome-candidato-list'>
                                        {candidato.nome} <span style={{ color: '#fbbc00' }}>{candidato.cognome}</span>
                                        <div style={{ color: 'grey' }}>{candidato.eta} anni</div>

                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            )}
            {candidati.length > 0 && (
                <div ref={descrizioneCandidatiRef} className='descrizione-candidati'>
                    <Container>
                        <Slider ref={sliderRef} {...slickSettings}>
                            {candidati.map((candidato, index) => (
                                <div className='box-candidato' key={index}>
                                    <Row>
                                        <Col xs={12} md={3} >
                                            <img
                                                className="d-block w-100 m-2"
                                                src={`img/candidati/${candidato.urlImmagine}`}
                                                alt="slide"
                                                style={{ maxHeight: '400px', objectFit: 'contain' }}
                                            />
                                            <div style={{ padding: '5px', fontSize: '22px' }}>
                                                {candidato.nome} <span style={{ color: '#fbbc00' }}>{candidato.cognome}</span>
                                                <div >{candidato.eta} anni</div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={9} >
                                            <div className='testo-candidato' >
                                                <div>{candidato.descrizione}</div>
                                                <div style={{margin: '25px 0'}}>
                                                <a href={`/doc/cv/${candidato.urlCurriculum}`} target="_blank" rel="noopener noreferrer">Curriculum Vitae</a>|
                                                <a href={`/doc/cg/${candidato.urlCasellario}`} target="_blank" rel="noopener noreferrer">Casellario Giudiziario</a>
                                            </div>

                                            </div>
                                          
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </Slider>
                    </Container>
                </div>
            )}

            <ScrollToTopButton />
        </div>
    );
}

export default Candidati;
