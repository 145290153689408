import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, message } = formData;
    const email = "info@eilmomento.org";

    const mailtoLink = `mailto:${email}?subject=Contatto%20da%20${encodeURIComponent(name)}&body=${encodeURIComponent(message)}`;

    window.location.href = mailtoLink;

    setFormData({ name: '', message: '' });
  };

  return (
    <div className="contact-form-container">
      <Container>

        <h2 style={{ textAlign: 'center', color: 'white', marginBottom: '20px' }}>Mandaci un Messaggio  <i className="bi bi-send"></i></h2>
        <Form onSubmit={handleSubmit} className="contact-form">
          <Form.Group controlId="formName" className='mb-3'>
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              placeholder="Inserisci il tuo nome"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>


          <Form.Group controlId="formMessage">
            <Form.Label>Messaggio</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Inserisci il tuo messaggio"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Button className="mt-3" variant="primary" type="submit">
            Invia
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default ContactForm;
