import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Manifesto = () => {
    
    return (
        <Container className='manifesto animate__animated animate__fadeInLeft'>
            <Row>
                <div className='titolo-manifesto'>
                    <img src="/img/logo.png" alt="logo" /> <span >il momento</span>
                </div>
            </Row>

            <Row>
                <Col>
                    <Card style={{ maxWidth: '700px', margin: 'auto' }}>
                        <Card.Body>
                            <Card.Text>
                                Siamo una comunità aperta riunitasi per la formazione di un nuovo progetto politico che vuole dare un contributo pragmatico
                                e innovativo alla vita della nostra città. Desideriamo costruire una rappresentanza politica che immagini un futuro di benessere per il nostro territorio,
                                portando nel dibattito pubblico una visione di governance più sostenibile, innovativa, giusta e che possa esaltare sia il privato sociale che il privato economico.
                            </Card.Text>

                            <Card.Text>
                                <b>Vogliamo concentrarci tanto sui temi ambientali quanto su quelli sociali:</b> riteniamo necessario un approccio integrato ad entrambe le sfere,
                                per trovare soluzioni e proporre politiche che siano efficaci, eque ed inclusive.
                            </Card.Text>
                            <Card.Text>
                                <b> Vogliamo offrire una politica libera da condizionamenti</b>, che non abbia bisogno di sottomettersi al meccanismo dello scambio
                                e che dia risposte concrete e costruite su analisi realistiche e buone prassi da altre realtà e comuni europei virtuosi.

                            </Card.Text>
                            <Card.Text>
                                <b>Vogliamo esplorare nuovi paradigmi di vivere la comunità</b>, contrari alle logiche predominanti dell'individualismo e della mancanza di relazioni.
                            </Card.Text>
                            <Card.Text>
                                In questo contesto, un Comune può fare tanto: creare spazi di relazione, supportare eventi di aggregazione, costruire una città a misura di persona, una bella città.
                            </Card.Text>
                            <Card.Text>
                                Inizieremo la nostra esperienza pubblica creando una lista che si presenterà alle prossime elezioni amministrative di Bassano del Grappa così da portare un approccio plurale e puntuale su temi specifici che riteniamo fondamentali per la gestione della nostra città.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
                <Col>
                    <Card style={{ maxWidth: '700px', margin: 'auto' }}>
                        <Card.Body>
                            <Card.Text>Abbiamo individuato 5 campi d'azione sui quali costruiremo la nostra campagna elettorale individuando e portando proposte concrete guidate da visioni più ampie e politiche:</Card.Text>
                            <div>
                                <ol>
                                    <li>
                                        <strong>Abitare la città:</strong> l'obiettivo è quello di favorire il benessere diffuso attraverso una gestione dello spazio pubblico, dei trasporti e della mobilità, che siano orientati al buon utilizzo delle risorse presenti. Vogliamo confrontarci con le migliori pratiche di sostenibilità italiane ed europee, nell'efficienza nella gestione del suolo e per l'edificabilità, sempre orientati al rispetto del territorio e degli spazi verdi.
                                    </li>
                                    <li>
                                        <strong>Aprire la città:</strong> lo stimolo principale è quello di connettere le aree vaste territoriali per concretizzare una progettualità più ampia. Creare una strategia su cultura e turismo a partire dalla storia e dalla natura dei luoghi, per contaminare ed essere contaminati, perché la città non ha solo dei confini determinati, ma è in dialogo continuo con il contesto.
                                    </li>
                                    <li>
                                        <strong>Attivare la città:</strong> vogliamo promuovere politiche di vicinanza e ascolto dei cittadini. Attivare vuol dire stimolare principalmente gli ambiti di partecipazione e la crescita delle comunità attraverso realtà che vanno sostenute costantemente: lo sport, l'associazionismo e le sue reti, le feste paesane, i comitati di quartiere.
                                    </li>
                                    <li>
                                        <strong>Innovare la città:</strong> sulla spinta all'innovazione delle imprese e delle politiche pubbliche per attrarre lavoro vogliamo stimolare un dialogo stabile tra i diversi ambiti privato/imprenditoriale e pubblico. Possiamo accogliere sguardi strategici di miglioramento tecnologico e di organizzazione da tante realtà aziendali del territorio, ascoltare case history di successo guardando alla città come sfida e laboratorio di miglioramento continuo.
                                    </li>
                                    <li>
                                        <strong>Prendersi cura della città:</strong> la prossimità è la parola che spinge a garantire la tutela delle categorie più deboli. Prendersi cura vuol dire essenzialmente saper ascoltare i bisogni e accogliere le diverse istanze da tutti i luoghi più a rischio della città. La cura dev'essere lo stimolo per ricordarsi che la comunità è fatta di tanti cittadini e cittadine diversi, ognuna ed ognuno con esigenze e fragilità differenti sui quali la città ha il compito di essere presente.
                                    </li>
                                </ol>
                            </div>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Manifesto;
