import { BrowserRouter as Router, Routes, Route ,useLocation } from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';
import './style/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/footer';
import Header from './components/header';
import ModalCaricamento from "./components/modalCaricamento";
import CookieBanner from './components/cookieBanner';
import Home from './routes/home';
import Candidati from './routes/candidati';
import Manifesto from './routes/manifesto';
import Quartieri from './routes/quartieri';
import Sindaco from './routes/sindaco';
/*
const Home = lazy(() => import('./routes/home'));
const Candidati = lazy(() => import('./routes/candidati'));
const Manifesto = lazy(() => import('./routes/manifesto'));*/

const WorkInProgress = () => (
  <div className="error-page">
    <div>
      <img src="/img/logo.png" alt="logo" className="logo-nav" /> <span style={{ fontSize: "24px", paddingLeft: '5px', fontWeight: '500', textTransform: 'uppercase' }}>il momento</span>
    </div>
    <h1>Lavori in Corso <i className="bi bi-pc-display-horizontal"></i></h1>
  </div>
);

const PageNotFound = () => (
  <div className="error-page">
    <div>
      <img src="/img/logo.png" alt="logo" className="logo-nav" /> <span style={{ fontSize: "24px", paddingLeft: '5px', fontWeight: '500', textTransform: 'uppercase' }}>il momento</span>
    </div>
    <h1>Pagina non trovata <i className="bi bi-emoji-frown-fill"></i></h1>
  </div>
);

function ScrollToTopOnLocationChange() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolling verso l'alto ogni volta che il percorso cambia
  }, [pathname]);

}



const PdfViewer = ({ pdfId }) => {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe 
        src={`https://drive.google.com/file/d/${pdfId}/preview`}
        title="PDF Viewer"
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
}



function App() {

  
  return (
    <Router>
      <Header /> 
      <Suspense fallback={<ModalCaricamento />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/candidati" element={<Candidati />} />
          <Route path="/manifesto" element={<Manifesto />} />
          <Route path="/programma-completo" element={<PdfViewer pdfId="1zr_vdOhJwNW4hakBGkQ10Ljp6VjMaLx_" />} />
          <Route path="/programma-quartieri"  element={<Quartieri />} />
          <Route path="/candidato-sindaco"  element={<Sindaco />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
      <Footer />
      <CookieBanner/> 
      <ScrollToTopOnLocationChange />
    </Router>
  );
}

export default App;
