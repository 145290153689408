import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(!localStorage.getItem('cookiesAccepted'));

    const handleAcceptCookies = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setShowBanner(false);
    };

    return (
        <>
            {showBanner && (
            <Alert variant="warning"  className="fixed-bottom text-center animate__animated animate__fadeInUp" style={{margin:0}}>
            <Alert.Heading><i className="bi bi-cookie"></i> Cookie</Alert.Heading>
            <p>
            Questo sito utilizza cookie tecnici e analitici per migliorare l'esperienza utente. 
            </p>
            <hr />
            <div className="d-flex justify-content-end">
            <Button onClick={handleAcceptCookies} variant="warning">
               Accetta e chiudi
            </Button>
            </div>
            </Alert>
            )}
        </>
    );
};

export default CookieBanner;
