import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Sindaco = () => {

    return (
        <div className='descrizione-candidati' style={{padding:'0'}}>
              <div className='barra-titolo'>
                <h1 >  <i className="bi bi-person-badge-fill"></i> Candidato sindaco</h1>
            </div>
            <Container>
 
                <div className='box-candidato animate__animated animate__fadeInLeft'>
                    <Row>
                        <Col xs={12} md={3} >
                            <img
                                className="d-block w-100 m-2"
                                src={`img/candidati/giovanni_zen_immagine.jpeg`}
                                alt="slide"
                                style={{ maxHeight: '400px', objectFit: 'contain' }}
                            />
                            <div style={{ padding: '5px', fontSize: '22px', textAlign: 'center' }}>
                            Giovanni <span style={{ color: '#fbbc00' }}>Zen</span>
                                <div >66 anni</div>
                            </div>
                        </Col>
                        <Col xs={12} md={9} >
                            <div className='testo-candidato' style={{ textAlign: 'center' }}>
                                <div>Sono Giovanni Zen, detto Gianni, classe 1958. <br /> Da sempre attivo nel mondo del volontariato, ho avuto la fortuna di essere eletto, a 36 anni, Deputato della Repubblica Italiana.
                                    Sono stato capo segreteria del Ministero dell’Università, e con Sergio Mattarella ho partecipato ai lavori della Commissione Antimafia.
                                    <br />Tornato a Bassano sono diventato Preside coprendo l’incarico in diversi istituti del territorio, per ultimo il Liceo G.B. Brocchi. <br />
                                    Sono sposato con Valeria, padre di tre figli e nonno di tre nipoti. <br /> Nel mio percorso ho potuto conoscere le necessità dei giovani, dei loro genitori e dei loro nonni. Per questo motivo ho accettato la sfida di candidarmi come Sindaco di Bassano con la lista civica è IL MOMENTO restituendo e mettendo a servizio della città la mia esperienza.</div>
                                <div style={{ margin: '25px 0' }}>
                                    <a href={`/doc/cv/giovanni_zen_cv.pdf`} target="_blank" rel="noopener noreferrer">Curriculum Vitae</a>|
                                    <a href={`/doc/cg/giovanni_zen_cg.pdf`} target="_blank" rel="noopener noreferrer">Casellario Giudiziario</a>
                                </div>

                            </div>

                        </Col>
                    </Row>
                </div>

                <div id="video" style={{ textAlign: 'center',marginTop:'50px' }}>
                    <Container>
                        <Row>
                            <Col xs={12} xl={12} className="mb-3">
                                <div className='video-home'>
                                    <iframe
                                        title='video-facebook2'
                                        src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fe.il.momento.Bassano%2Fvideos%2F2162177154120880%2F&show_text=false&width=560&t=0"
                                        style={{ border: "none", overflow: "hidden" }}
                                        allowFullScreen={true}
                                        scrolling="no"
                                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                    ></iframe>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Container>
        </div>
    );
};

export default Sindaco;
